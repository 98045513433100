import { useDispatch } from 'react-redux';
import axios from '../utils/axiosConfig';
import { 
    setChatMessages, 
    setChatMessagesError, 
    setChatMessagesLoading, 
    setChatSubquestions, 
    setChatSubquestionsError, 
    setChatSubquestionsLoading 
} from '../redux/slices/chat';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
axios.interceptors.request.use(async function (config:any) {
    let  token = ''
    if(Clerk?.session ){
        token =  await Clerk.session.getToken({template:"main"});
    }
    config.headers.Authorization = `Bearer ${token}`
    return config;
});
axios.defaults.timeout = 60000;



const useChatService = () => {
    const dispatch = useDispatch();

    const handleChatQuery = async (body: any) => {
        dispatch(setChatMessagesLoading(true));
        try {
            const response = await axios.post<any>(`${API_BASE_URL}/handle-query`,body);
            dispatch(setChatMessages(response.data));
        } catch (error) {
            dispatch(setChatMessagesError(error));
        } finally {
            dispatch(setChatMessagesLoading(false));
        }
    };

    const handleChatSubquestions = async (body: any) => {
        dispatch(setChatSubquestionsLoading(true));
        try {
            const response = await axios.post<any>(`${API_BASE_URL}/handle-subquestions`,body);
            dispatch(setChatSubquestions(response?.data?.sub_questions));
        } catch (error) {
            dispatch(setChatSubquestionsError(error));
        } finally {
            dispatch(setChatSubquestionsLoading(false));
        }
    };

    return { handleChatQuery,handleChatSubquestions };
};

export default useChatService;