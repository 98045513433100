import { createSlice } from '@reduxjs/toolkit';

export const chatMessageSlice = createSlice({
    name: 'chatMessage',
    initialState: {
        messages: [],
        loading: false,
        error: null,
        chatSubquestions: [],
        chatSubquestionsLoading: false,
        chatSubquestionsError: null
    },
    reducers: {
        setChatMessages: (state: any, action) => {
            state.messages = [...state.messages,{text:action?.payload}]
        },
        setChatMessagesLoading: (state: any, action) => {
            state.loading = action.payload
        },
        setChatMessagesError: (state: any, action) => {
            state.error = action.payload
        },
        setChatSubquestions: (state: any, action) => {
            state.chatSubquestions = action?.payload
        },
        setChatSubquestionsLoading: (state: any, action) => {
            state.chatSubquestionsLoading = action.payload
        },
        setChatSubquestionsError: (state: any, action) => {
            state.chatSubquestionsError = action.payload
        }
    }
});

export const { 
    setChatMessages, setChatMessagesLoading, setChatMessagesError, 
    setChatSubquestions, setChatSubquestionsLoading, setChatSubquestionsError 
} = chatMessageSlice.actions;

export default chatMessageSlice.reducer;

